import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Home1 from "./Pages/new/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home2 from "./Pages/Home2";
import Voluntari from "./Pages/Voluntari";
import Ptc from "./Pages/Ptc";
import Party from "./Pages/Party";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cazari from "./Pages/Cazari";
import Flayer from "./Pages/Flayer";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/new" element={<Home1 />} />
          <Route path="/secret" element={<Home />} />
          <Route path="/voluntariat" element={<Voluntari />} />
          <Route path="/eventptc" element={<Ptc />} />
          <Route path="/party" element={<Party />} />
          <Route path="/specializari" element={<Cazari />} />
          <Route path="/flayer" element={<Flayer />} />
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
